<template>
    <div class="container container-bill">
        <div class="row p-0">
            <div class="form-search col-12 border p-0 mb-3">
                <div class="border border-top-0 rounded p-1 pr-2">
                        <span class="text-left">検索条件</span>
                        <i class="fa fa-bars text-primary float-right pt-1"></i>
                    </div>
                <div class="row p-3 pt-0">
                    <div class="col-11">
                        <div class="row">
                            <div class="col-md-1 col-sm-12">
                                年度
                                <input
                                type="number"
                                class="form-control"
                                />
                                <span class="text-danger"></span>
                            </div>

                            <div class="col-md-1 col-sm-12">
                                実施日
                                <date-picker
                                autocomplete="off"
                                :config="option_datetime"
                                name="tel_datetime"
                                >
                                </date-picker>
                                <span class="text-danger"></span>
                            </div>
                            -
                            <div class="col-md-1 col-sm-12">
                                実施日
                                <date-picker
                                autocomplete="off"
                                :config="option_datetime"
                                name="tel_datetime"
                                >
                                </date-picker>
                                <span class="text-danger"></span>
                            </div>

                            <div class="col-md-2 col-sm-12">
                                保健指導機関
                                <select class="form-select">
                                <option
                                    v-for="option in options"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                                </select>
                            </div>

                            <div class="col-md-2 col-sm-12">
                                請求・報告先
                                <select class="form-select">
                                <option
                                    v-for="option in options"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                                </select>
                            </div>

                            <div class="col-md-4 col-sm-12">
                                XMLデータ出力状況
                                <div class="row">
                                    <div class="col-4">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            />
                                        <label class="ml-3">未</label>
                                    </div>
                                    <div class="col-4">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            />
                                        <label class="ml-3">一部済み</label>
                                    </div>
                                    <div class="col-4">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            />
                                        <label class="ml-3">全て済み</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row">
                            <div class="col-12 pr-0">
                                <button type="button" class="btn btn-secondary w-100">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                    クリア
                                </button>
                            </div>

                            <div class="col-12 pr-0">
                                <button type="button" class="btn btn-success w-100">
                                    <i class="fa fa-search" aria-hidden="true"></i>検索
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-4 border">
                <table class="table">
                    <colgroup><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""></colgroup>
                    <thead>
                        <tr>
                            <th>年度</th>
                            <th>請求・報告先機関</th>
                            <th>請求日</th>
                            <th>XML出力</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>22年度</td>
                            <td>[XXXXXXXXX]送付先A</td>
                            <td>2022-08-14</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>22年度</td>
                            <td>[XXXXXXXXX]送付先A</td>
                            <td>2022-08-14</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-8 border p-3 bill-info">
                <div class="row">
                    <p class="m-0 mt-5">XMLデータ出力設定</p>
                    <hr class="m-0">
                    <div class="col-md-4 col-sm-12 ml-5">
                        年度
                        <table>
                            <tr>
                                <td class="border w-100"></td>
                                <td class="border bg-gray">回</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <p class="m-0 mt-5">送付情報</p>
                    <hr class="m-0">

                    <div class="col-md-12 col-sm-12 ml-5">
                        <table class="row">
                            <tr>
                                <td class="border bg-gray col-2">送付元機関</td>
                                <td class="border col-3"></td>
                                <td class="border bg-gray col-2">送付先機関</td>
                                <td class="border col-3"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <p class="m-0 mt-5">集計情報</p>
                    <hr class="m-0">

                    <div class="col-md-12 col-sm-12 ml-5">
                        <table class="row">
                            <tr class="row">
                                <td class="border bg-gray col-2">対象者人数</td>
                                <td class="border col-2"></td>
                            </tr>
                            <tr class="row">
                                <td class="border bg-gray col-2">保険者請求額 合計</td>
                                <td class="border col-2"></td>
                                <td class="border bg-gray col-2">窓口負担額 合計</td>
                                <td class="border col-2"></td>
                                <td class="border bg-gray col-2">算定額総計</td>
                                <td class="border col-2"></td>
                            </tr>
                            <tr class="row" >
                                <td class="border bg-gray col-2">請求出力額 合計</td>
                                <td class="border col-2"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <p class="m-0 mt-10">明細情報</p>
                    <hr class="m-0">

                    <div class="col-md-12 col-sm-12">
                        <div class="border container-bill__info">
                            <table class="container-bill__info-content">
                                <tr class="border">
                                    <th>
                                        <input type="checkbox"
                                            class="form-check-input"
                                            />
                                    </th>
                                    <th>対象者</th>
                                    <th>保健指導区分</th>
                                    <th>実施時点</th>
                                    <th>対象日</th>
                                    <th>実施ポイント</th>
                                    <th>算定額(円)</th>
                                    <th>保険者請求額(円)</th>
                                    <th>窓口負担額(円)</th>
                                    <th>請求出力額(円)</th>
                                    <th>請求書送付先</th>
                                    <th>XML出力</th>
                                </tr>
                                <tr>
                                    <td><input
                                        type="checkbox"
                                        class="form-check-input"
                                        />
                                    </td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                </tr>
                                <tr>
                                    <td><input
                                        type="checkbox"
                                        class="form-check-input"
                                        />
                                    </td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                    <td>21</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="container-bill__button-download mt-5">
                    <button type="button" class="btn btn-success">
                        <i class="fa fa-search" aria-hidden="true"></i>検索
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    name: "list",
    components: {},
    data() {
        return {
            options: [
                {
                    value: 1,
                    name: "name 1"
                }
            ],
        };
    },
    computed: {
        option_datetime() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
    },
    methods: {
    }
}
</script>
<style lang="sass">
    .container-bill
        & .bg-gray
            background-color: #c5c5c5
        & .bill-info tr td
            padding: 0.375rem 0.75rem
        & .bill-info tr th
            padding: 0.375rem 0.75rem
        &__info
            overflow: scroll
            scroll-behavior: smooth
            overflow-y: hidden
        &__info-content
            width: 1500px

        &__button-download
            float: right

</style>
